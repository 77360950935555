import React from "react";
import { Col, Row } from "react-bootstrap";
import { strings } from "../strings/strings";
import * as styles from '../styles/legend.module.css'

const Legend = () => {
    return (
        <Row className={styles.legend + " p-4"}>
            <Col xxl='1' />
            <Col >
                <h3>{strings.motto}</h3>
            </Col>
            <Col xxl='1' />
        </Row>
    );
}

export default Legend;