import { Col, Container, Row } from "react-bootstrap";
import * as styles from '../styles/newsletter.module.css'
import { strings } from "../strings/strings";
import boldFontLogo from '../images/bold-font-logo.png'
import React from "react";

const Newsletter = () => {
    return (
        <Row className={styles.newsletter + " p-4"}>
            <Col xl='2' lg='1' />
            <Col xl='8' lg='10' md='12'>
                <NewsletterInfo />
            </Col>
            <Col xl='2' lg='1' />
        </Row>
    );
}

const NewsletterInfo = () => {
    return (
        <Row className="py-2">
            <Col xl='4' lg='5' md='5' className="container">
                <img className={styles.logo} src={boldFontLogo} />
            </Col>
            <Col xl='8' lg='7' md='7'>
                <Row>
                    <div className="container p-4">
                        <span><b>Bold Font Snippets</b> {strings.newsletter}</span>
                    </div>
                </Row>
                <Row>
                    <NewsletterForm />
                </Row>
            </Col>
        </Row>
    );
}

const NewsletterForm = () => {
    return (
        <div id="revue-embed" className={styles.container + " form"}>
            <form action="https://www.getrevue.co/profile/fontecha/add_subscriber" method="post" id="revue-form" name="revue-form" target="_blank">
                <div className="py-3 revue-form-group">
                    <input className="form-control form-control-lg revue-form-field" placeholder="Your email address..." type="email" name="member[email]" id="member_email" />
                </div>
                <div className="d-grid gap-2 revue-form-actions">
                    <button type="submit" className={styles.revueButton + " btn btn-primary btn-lg"} value="Subscribe" name="member[subscribe]" id="member_submit">Subscribe</button>
                </div>
                <div className={styles.revueFormFooter + " p-3"}>By subscribing, you agree with Revue's <a target="_blank" href="https://www.getrevue.co/terms">Terms of Service</a> and <a target="_blank" href="https://www.getrevue.co/privacy">Privacy Policy</a>.</div>
            </form>
        </div>
    );
}

export default Newsletter;