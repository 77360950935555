import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import * as styles from '../styles/blog.module.css';
import { Service } from './hooks/useService';

type BlogFeed = {
    title: string
    items: BlogItem[]
};

type BlogItem = {
    creator: string
    title: string
    link: string
    pubDate: Date
    snippet: string
    image?: string
};

const useBlogFeedService = () => {
    const [result, setResult] = useState<Service<BlogFeed>>({
        status: 'loading'
    });

    useEffect(() => {
        fetch('/api/get-posts')
            .then(response => response.json())
            .then(response => setResult({ status: 'loaded', payload: response }))
            .catch(error => setResult({ status: 'error', error }));
    }, []);

    return result;
}

const Blog = () => {
    const service = useBlogFeedService();

    const getFormattedDate = (blogItem: BlogItem): string  => {
        return new Date(blogItem.pubDate).toLocaleDateString("en-US", {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    }

    const getPostComponent = (item: BlogItem) => {
        return(
            <Col xs='12' sm='6' md='4' className={styles.post + " p-3"}>
                <a href={item.link}>
                    <div className={styles.postImage}>
                        <img src={item.image} alt="blog post" />
                        <div className={styles.postImageOverlay}>
                            <p>{getFormattedDate(item)}</p>
                        </div>
                    </div>
                    <h4 className="my-3">{item.title}</h4>
                </a>
            </Col>
        );
    }

    const getPostListComponent = (items: BlogItem[]) => {
        return (
            <Row>
                {items.map(item => (getPostComponent(item)))}
            </Row>
        );
    }

    const getReadMoreComponent = (message: string) => {
        return (
            <div className={styles.readMore + " my-5"}>
                <p className="text-center"><a href="https://blog.jcfontecha.com">{message}</a></p>
            </div>
        );
    }

    return (
        <Container fluid="xxl" className={styles.blog}>
            <h2 className="mt-5">From the blog</h2>
            { service.status === 'loading' && <p className={styles.message + " p-4"}>Loading...</p> }
            { service.status === 'loaded' && getPostListComponent(service.payload.items) }
            { service.status === 'error' && <p className={styles.message + " p-4"}>Whoops. I wasn't able to load posts right now.</p> }
            { getReadMoreComponent("Go to the blog") }
        </Container>
    )
}

export default Blog;

