import { strings } from '../strings/strings';
import me from '../images/me-circular.png';
import microsoftLogo from '../images/microsoft-logo.png';
import * as styles from '../styles/hero.module.css';
import Socials, { SocialNetwork } from './socials';
import { Col, Container, Row } from 'react-bootstrap';
import React from 'react';

function Hero() {
    return (
        <Container fluid="xxl" className={styles.hero}>
            <Row>
                <Col md='12' lg='5' className="order-lg-5">
                    <HeroImage />
                    <Socials option='some' socials={[SocialNetwork.YouTube, SocialNetwork.Twitter, SocialNetwork.Instagram, SocialNetwork.LinkedIn]}></Socials>
                </Col>
                <Col md='12' lg='7' className="order-lg-1 d-grid gap-5">
                    <HeroIntro />
                </Col>
            </Row>
        </Container>
    );
}

const HeroIntro = () => {
    return (
        <div className='p-4'>
            <h2 className='p-1'>{strings.hero}</h2>
        </div>
    );
}

const Experience = () => {
    return (
        <div className={styles.experience + ' row p-3'}>
            <h4 className="my-5">WORK EXPERIENCE</h4>
            <span>Software Engineer → <b>Microsoft</b>, Seattle, 2019-Present</span>
            <span>Technical Leader → <b>Citibanamex</b>, Mexico City, 2018-2019</span>
            <span>iOS Developer → <b>Globant</b>, Mexico City, 2017–2018</span>
        </div>
    );
}

const HeroImage = () => {
    return (
        <img className={styles.me + " mx-auto d-block"} src={me} alt="me smiling"/>
    );
}

export default Hero;